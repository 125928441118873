import React, { FC } from 'react'

type CircleFilledPropsType = {
  fill?: string
  size?: number
}

export const CircleFilled: FC<CircleFilledPropsType> = ({
  fill,
  size = 10,
  ...rest
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="5" cy="5.62646" r={5} fill={fill} />
    </svg>
  )
}
