import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { TicketTicketCustomFieldInputTypeChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

import { metaValuesInputTypesLabelMap } from './MetaValuesTypes'

export const customFieldInputTypesLabelMap: Record<
  TicketTicketCustomFieldInputTypeChoices,
  ReactNode
> = {
  ...metaValuesInputTypesLabelMap,
  SIGNATURE: <T _str="Signature" />,
}

export const customFieldInputTypesSectionTitleMap: Record<
  TicketTicketCustomFieldInputTypeChoices,
  ReactNode
> = {
  SELECT: <T _str="Select option" />,
  MULTI_SELECT: <T _str="Multi-select option" />,
  NUMBER: <T _str="Number input" />,
  TEXT_AREA: <T _str="Text area" />,
  SIGNATURE: <T _str="Signature" />,
}

export const customFieldInputTypeOptions = createOptionsFromLabelMap(
  customFieldInputTypesLabelMap
)

export const getCustomFieldInputTypesPlaceholder = (
  type: TicketTicketCustomFieldInputTypeChoices,
  t: (str: string) => string
) => {
  switch (type) {
    case 'SELECT':
      return t('Select 1 option')
    case 'MULTI_SELECT':
      return t('Select 1 ore more options')
    case 'NUMBER':
      return t('Enter a value')
    case 'TEXT_AREA':
      return t('Enter text')
    default:
      return ''
  }
}
